<template>
  <div>
    <br />
    <div class="col-sm-12 text-right">
      <h1>Fideicomisos</h1>
    </div>
    <br />
    <DataGridV3 :configuracion="dataGridConfig" :data="dataSourceGrid" :seleccionar="seleccionar" ref="gridFideicomisos"  @ready="cargarAcciones">
      <div class="row">
        <div class="col-sm-auto">
          <button class="btn btn-info" @click="cargar()"> <i class='fa fa-refresh'></i> Recargar</button>
        </div>
        <div class="col-sm-auto" v-if="$auth.can('garantias_app','fideicomisos_crear')">
          <button  class="btn btn-primary" @click="crear"><i class='fa fa-plus-circle'></i> Nuevo</button>
        </div>
      </div>

    </DataGridV3>

    <FideicomisoForm v-if="openModalForm" :fideicomiso_id="itemSeleccionado.id" @close="calcelar_seleccion"></FideicomisoForm>


    <Modal v-if="openModalEliminar" :options="{width: '30vw',type:'error'}" @close="openModalEliminar = false">
      <div class="title">Eliminar: {{ itemSeleccionado.nombre }} </div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12 text-center">
            Eliminarás la aplicación  {{ itemSeleccionado.nombre }} <br/>¿Deseas continuar?
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="row justify-content-end">
            <div class="col-auto"><button class="btn btn-primary" @click="eliminar"><i class="fa fa-check-square mr-sm-1"></i>Continuar</button></div>
            <div class="col-auto"><button class="btn btn-outline-secondary" @click="calcelar_seleccion"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
          </div>
        </div>
      </div>
    </Modal>

  </div>
</template>

<script>
import DataGridV3 from '@/components/DataGridV3.vue'
import Modal from '@/components/Modal'
import FideicomisoForm from "@/apps/garantias_app/pages/fideicomisos/FideicomisoForm.vue";

export default {
  name: "Fideicomisos",
  data(){
    return {
      openModalForm: false,
      openModalEliminar: false,
      dataGridConfig: {
        name: 'fideicomisos',
        cols: {
          acciones: '',
          id: 'ID Crédito',
          nombre_cliente: 'Nombre Cliente',
          monto: 'Monto',
          estatus: 'Estatus',
        }
        ,filters: {
          cols: {
            id: 'ID Crédito',
            nombre_cliente: 'Nombre Cliente'
          }
        }
        ,paginator: {
          pagina_actual: 1
          ,total_registros: 1
          ,registros_por_pagina: 20
        }
        ,mutators: {
          acciones: function(val,row,vue) {
            let acciones = "<div class='text-center'>";
            if (vue.$auth.can('garantias_app','fideicomisos_editar'))
              acciones += "<i data-action='editar' data-opcion='"+vue.$helper.toJson(row)+"' class='btn-sm btn-editar rounded-circle btn-acciones fa fa-edit'></i>";

            return acciones+"</div>";
          },
        },
        show_search: true,
        search: ['nombre'],
        selector: false,
      },
      seleccionar: false,
      dataSourceGrid: [],
      accionesCargadas: false,
      itemSeleccionado: {
        id: null,
        nombre_cliente: null,
        monto: null,
        estatus: null
      }
    }

  },
  components: {
    FideicomisoForm,
    DataGridV3,
    Modal
  },
  computed: {

  },
  mounted() {
    this.cargar()
  },
  methods: {
    cargarAcciones() {
        let btns = document.querySelectorAll('.btn-acciones');
        btns.forEach(btn => {
          btn.removeEventListener('click', this.ejecutarAccionBtn )
          btn.addEventListener('click', this.ejecutarAccionBtn)
        })
    },
    ejecutarAccionBtn(e){
      let seleccion  = this.$helper.fromJson(e.target.dataset.opcion);
      if (seleccion.id !== this.itemSeleccionado.id) {
        let accion = e.target.dataset.action;
        this.itemSeleccionado = {...seleccion}
        this[accion](seleccion);
      }
    }
    ,calcelar_seleccion(){
      this.openModalForm = false;
      this.openModalEliminar = false;

      this.itemSeleccionado = {
        id: null,
        nombre_cliente: null,
        monto: null,
        estatus: null
      };
    },
    crear(){
       this.calcelar_seleccion()
      this.openModalForm = true;
    },
    editar(){
      this.openModalForm = true;
    },
    pre_eliminar(){
        this.openModalEliminar = true;
    },

    async eliminar(){
      this.openModalEliminar = false;
      //await api.eliminar(this.itemSeleccionado.id).then(() => {
        this.$helper.showMessage('Editar aplicación','Aplicación eliminada exitosamente.', 'success', 'alert')
      /**  this.cargar()
        this.calcelar_seleccion()
      })**/
    },

    async cargar() {
      let items = []
      for (let i = 0; i < 10; i++) {
        items.push({
          id: `0103090070001483-${i}`,
          nombre_cliente: "Cliente Test",
          monto: '$1,000,000',
          estatus: 'Activo',
        })
      }
      this.dataSourceGrid = items
    },
  }
}
</script>
<style lang="css">
.btn-eliminar{
  background-color: #d7717a;
  color: white;
}
.btn-editar{
  background-color: #196eef;
  color: white;
}
.btn-eliminar:hover, .btn-editar:hover{
  cursor: pointer;
  filter: brightness(80%);
}
</style>

